/***** INFO FOOTER STYLES *****/
.info-footer {
  background: #333;
  padding: 80px 0;
  @media only screen and (max-width: 600px) {
    padding: 40px 0;
  }
  .btn {
    @media only screen and (max-width: 600px) {
      margin: 32px auto;
    }
  }
}
.info-footer .sellers-link {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.legal-text {
  color: rgba(255, 255, 255, 0.75);
}
.copyright-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}
.social-links-icons {
  display: flex;
  gap: 16px;
  justify-content: center;
}
.social-links-icon {
  height: 48px;
  width: 48px;
  border-radius: 100px;
  border: 1px solid $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 300ms linear;
  &:hover {
    transform: scale(1.1);
  }
  img {
    height: 24px;
  }
}
.logo-container {
  width: 200px;
  padding-bottom: 20px;
}
.logo-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
